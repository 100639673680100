import {Layout} from "@components";
import * as styles from './style.module.scss';
import {TypographyUI} from "@ui";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

const TechnicalBreakPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Самозанятые.рф - На сайте проводятся технические работы</title>
      <meta name="description" content={`На сайте проводятся технические работы`} />
    </Helmet>
    <Layout partnersFooter={false}>
        <div className={styles.container}>
            <div className={styles.text}>
                <TypographyUI.H1 swichPoint={LAPTOP_MOBILE_POINTER} className={styles.title}>На сайте проводятся технические работы</TypographyUI.H1>
                <TypographyUI.Body1Regular className={styles.description}>Приносим свои извинения за доставленные неудобства</TypographyUI.Body1Regular>
            </div>

            <div className={styles.background}>
                <StaticImage
                    className={styles.backgroundImg}
                    src="../../images/500/Image.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={576}
                    height={441}
                    layout="fixed"
                    objectFit="contain"
                    alt="background-img"
                />
            </div>
        </div>
    </Layout>
  </>
);

export default TechnicalBreakPage
